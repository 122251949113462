import {useIntl} from "gatsby-plugin-react-intl";
import {Layout} from "../components/Layout";
import {BlockTitle} from "../components/BlockTitle";
import {Typography} from "../components/Typography";
import {MarkdownContent} from "../components/MarkdownContent";
import React from "react";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {graphql} from "gatsby";
import {ConsultationForm} from "../components/ConsultationForm";
import {Card} from "../components/Card";
import {ClippedImage} from "../components/ClippedImage";
import {TypedWrapper} from "../components/TypedWrapper";

const styles = require('./consultation.module.scss');

export const query = graphql`query ConsultationPage($locale: String!) {
  page: contentfulConsultationPage(node_locale: {eq: $locale}) {
    title
    intro {
      childMarkdownRemark {
        html
      }
    }
    exampleQuestionsTitle
    exampleQuestions {
      childMarkdownRemark {
        html
      }
    }
    exampleQuestionsImage {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 350, height: 500, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
    aboutUsTitle
    aboutUsContent {
      childMarkdownRemark {
        html
      }
    }
    aboutUsImage {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 350, height: 500, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
    seo {
      ...Seo
    }
  }
}
`
export default function ConsultationPage({data, pageContext}) {
    const intl = useIntl();
    return <Layout enableRecaptchaBadge={true}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   jobsCount={pageContext.jobsCount}
                   seo={data.page.seo}
                   title={intl.formatMessage({id: 'menu.consultation'})}
                   companyData={pageContext.companyData}
                   services={pageContext.services}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper className={styles.hero}>
            <div className={styles.heroContent}>
                <BlockTitle><TypedWrapper content={data.page.title} /></BlockTitle>
                <MarkdownContent component={Typography} content={data.page.intro}/>
            </div>
            <Card className={styles.heroForm}>
                <h2 className={styles.heroFormTitle}>{intl.formatMessage({id: 'consultation.title'})}</h2>
                <ConsultationForm/>
            </Card>
        </PageMainColumnWrapper>
        <PageMainColumnWrapper className={styles.sideSectionWrapper}>
            <div className={styles.contentWrapper}>
                <BlockTitle>{data.page.exampleQuestionsTitle}</BlockTitle>
                <MarkdownContent component={Typography} content={data.page.exampleQuestions}/>
            </div>
            <div className={styles.imageWrapperFirst}>
                <ClippedImage
                    slopeHeight="190px"
                    className={styles.clippedImage}
                    image={data.page.exampleQuestionsImage.localFile.childImageSharp}
                />
            </div>
        </PageMainColumnWrapper>
        <PageMainColumnWrapper className={styles.sideSectionWrapper}>
            <div className={styles.imageWrapperSecond}>
                <ClippedImage
                    slopeHeight="190px"
                    direction="toUp"
                    className={styles.clippedImage}
                    image={data.page.aboutUsImage.localFile.childImageSharp}
                />
            </div>
            <div className={styles.contentWrapper}>
                <BlockTitle>{data.page.aboutUsTitle}</BlockTitle>
                <MarkdownContent component={Typography} content={data.page.aboutUsContent}/>
            </div>
        </PageMainColumnWrapper>
        <Card className={styles.bottomForm}>
            <h2 className={styles.bottomFormTitle}>{intl.formatMessage({id: 'consultation.title'})}</h2>
            <ConsultationForm/>
        </Card>

    </Layout>;
}
