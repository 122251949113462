import {useForm, Controller} from "react-hook-form";
import {useIntl} from "gatsby-plugin-react-intl";
import React, {useEffect} from 'react';
import {FormField} from "../FormField";
import {TextInput} from "../TextInput";
import {Checkbox} from "../Checkbox";
import {LangLink} from "../LangLink";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {toast} from "react-toastify";
import {sendConsultationForm} from "../../functions/sendConsultationForm";
import {Button} from "../Button";
import {FormButtons} from "../FormButtons";
import {initRecaptcha} from "../../functions/initRecaptcha";

const styles = require('./ConsultationForm.module.scss');

const schema = yup.object()
    .shape({
        phone: yup.string()
            .required('error.required')
            .trim(),
        email: yup.string()
            .required('error.required')
            .email('error.email.invalid')
            .trim(),
        subject: yup.string()
            .required('error.required')
            .min(10, 'error.subject.tooShort')
            .trim(),
        agreement: yup.bool()
            .test('test', 'error.agreement.required', value => value)
    });

export function ConsultationForm() {
    const intl = useIntl();

    useEffect(() => {
        initRecaptcha();
    }, []);

    const {register, handleSubmit, control, errors, formState, reset} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onBlur',
        mode: 'onBlur',
        defaultValues: {
            email: '',
            subject: '',
            phone: '',
            agreement: false
        }
    });

    function translateError(error) {
        if (error) {
            return intl.formatMessage({id: error})
        }
    }

    const onSubmit = (values) => {
        return new Promise((resolve, reject) => {
            global.grecaptcha.ready(function () {
                global.grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'consultationForm'})
                    .then((token) => {
                        return sendConsultationForm({
                            captchaToken: token,
                            phone: values.phone,
                            email: values.email,
                            subject: values.subject
                        });
                    })
                    .then(() => {
                        reset();
                        toast.success(intl.formatMessage({id: 'consultation.form.successMessage'}), {
                            autoClose: 10000,
                            position: "top-center"
                        });
                    })
                    .catch(reject)
            });
        })
    };

    return <form className={styles.root}
                 onSubmit={handleSubmit(onSubmit)}>
        <FormField>
            <Controller
                control={control}
                name="phone"
                render={(props) => {
                    return <TextInput {...props}
                                      label={intl.formatMessage({id: 'consultation.form.phone'})}
                                      disabled={formState.isSubmitting}
                                      error={translateError(errors?.name?.message)}
                    />
                }}
            />
        </FormField>
        <FormField>
            <Controller
                control={control}
                name="email"
                render={(props) => {
                    return <TextInput {...props}
                                      type="email"
                                      label={intl.formatMessage({id: 'consultation.form.email'})}
                                      disabled={formState.isSubmitting}
                                      error={translateError(errors?.email?.message)}
                    />;
                }}
            />

        </FormField>
        <FormField>
            <Controller
                control={control}
                name="subject"
                render={(props) => {
                    return <TextInput
                        {...props}
                        className={styles.message}
                        error={translateError(errors?.subject?.message)}
                        disabled={formState.isSubmitting}
                        label={intl.formatMessage({id: 'consultation.form.subject'})}
                    />
                }}
            />
        </FormField>
        <FormField>
            <Checkbox ref={register('agreement')}
                      name="agreement"
                      disabled={formState.isSubmitting}
                      error={translateError(errors?.agreement?.message)}>
                {intl.formatMessage({
                    id: 'consultation.form.agreement.label'
                }, {
                    link: <LangLink
                        to="/privacy">{intl.formatMessage({id: 'consultation.form.agreement.policy'})}</LangLink>
                })}
            </Checkbox>
        </FormField>
        <FormButtons className={styles.buttons}>
            <Button component="button" type="submit"
                    disabled={formState.isSubmitting}>
                {formState.isSubmitting ? intl.formatMessage({id: 'consultation.form.sending'}) : intl.formatMessage({id: 'consultation.form.submit'})}
            </Button>
        </FormButtons>
    </form>

}
