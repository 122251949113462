import React from 'react';
import Typed from 'react-typed';

export function TypedWrapper({content}) {
    return <>{parseTypedJsString(content)}</>
}

const TYPED_REGEXP = /\[\s*(".*?")\s*]/ig;

function parseTypedJsString(str) {
    const matches = str.matchAll(TYPED_REGEXP);

    const chunks = [];
    let componentIndex = 0;
    let position = 0;
    for (const match of matches) {
        chunks.push(str.substr(position, match.index - position));
        position = match.index + match[0].length;
        const phrases = match[1].split(',')
            .map(x => x.trim().replace(/^"/, '').replace(/"$/, ''));
        chunks.push(
            <Typed strings={phrases} key={componentIndex++} typeSpeed={40} backDelay={4000} loop/>
        );
    }

    return chunks.length > 0 ? chunks:str;
}

