// extracted by mini-css-extract-plugin
export var bottomForm = "consultation-module--bottomForm--tsaYd";
export var bottomFormTitle = "consultation-module--bottomFormTitle--t2I9b";
export var contentWrapper = "consultation-module--contentWrapper--uuRpd";
export var hero = "consultation-module--hero--rXmRZ";
export var heroContent = "consultation-module--heroContent--+buk6";
export var heroForm = "consultation-module--heroForm--Itmcg";
export var heroFormTitle = "consultation-module--heroFormTitle--Ap3RW";
export var imageWrapperFirst = "consultation-module--imageWrapperFirst--G42gW";
export var imageWrapperSecond = "consultation-module--imageWrapperSecond--3eap9";
export var sideSectionWrapper = "consultation-module--sideSectionWrapper--X9QZb";